<!--
 * @Description:  
 * @Author: cuncai.zhang@simceredx.com
 * @Date: 2023-11-08 10:53:10
 * @LastEditTime: 2023-11-09 10:05:14
 * @LastEditors: cuncai.zhang@simceredx.com
-->
<template>
  <div class="content">
    <el-card class="box-card" shadow="never">
      <div slot="header" class="card-header">
        <div class="card-header-left">
          产品组名称： <span>{{ $route.query.name }}</span>
        </div>
        <div class="card-header-right">
          <el-button type="primary" size="small" @click="onOpenAdd"
            >添加</el-button
          >
          <el-button type="danger" size="small" @click="onClearProduct"
            >清空</el-button
          >
        </div>
      </div>

      <div :style="{ overflowY: 'auto', height: tableHeight }">
        <el-table
          size="small"
          :data="tableData"
          border
          height="calc(100% - 60px)"
        >
          <el-table-column label="SKUID" prop="skuId" align="center" />
          <el-table-column label="产品名称" prop="productName" align="center" />
          <el-table-column
            label="产品状态"
            prop="productStatusDesc"
            align="center"
          />
          <el-table-column label="操作人" prop="updateBy" align="center" />
          <el-table-column label="更新时间" prop="updateTime" align="center" />
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <el-button
                type="primary"
                size="small"
                @click="onDelete(scope.row)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <div style="display: flex; justify-content: flex-end; margin: 10px 0">
          <el-pagination
            :current-page="page.current"
            :page-sizes="page.sizes"
            :page-size="page.size"
            layout="total, sizes, prev, pager, next, jumper"
            :total="page.total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </div>
      </div>
    </el-card>
    <el-dialog title="添加产品" :visible.sync="dialogVisible" width="30%">
      <span style="padding-right: 10px">选择产品</span>
      <el-select
        v-model="selectProduct"
        style="width: 60%"
        multiple
        filterable
        placeholder="请选择"
      >
        <el-option
          v-for="item in options"
          :key="item.skuId"
          :label="item.nameDesc"
          :value="item.skuId"
          :disabled="!!item.productGroupCode"
        >
          <span>{{ item.skuId }}</span>
          <span style="padding: 0 20px">{{ item.productName }}</span>
          <span>{{ item.productStatusDesc }}</span>
        </el-option>
      </el-select>
      <span slot="footer" class="dialog-footer" style="text-align: center">
        <el-button type="primary" @click="onConfirm">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectProduct: [],
      options: [],
      dialogVisible: false,
      tableData: [], // 表格数据
      tableHeight: "850px",
      page: {
        current: 1, // 当前在第几页
        sizes: [10, 20, 50, 100], // 每页显示多少条选择数组
        size: 10, // 每页显示多少条
        total: 0, // 共几条
      },
    };
  },
  mounted() {
    // 获取高度
    this.tableHeight = document.body.clientHeight - 220 + "px";
    // 全选的数据
    this.oneDimensionalList = [];
    console.log("this.$route.params", this.$route);
    this.getList();
    this.getProductList();
  },
  methods: {
    getProductList() {
      this.axios.get("PRODUCTLIST", {}).then((result) => {
        if (result.code === 0) {
          this.options = result.data.map((item) =>
            Object.assign({}, item, {
              nameDesc: `${item.skuId} ${item.productName} ${item.productStatusDesc}`,
            })
          );
        }
      });
    },
    onConfirm() {
      if (this.selectProduct.length === 0) {
        this.dialogVisible = false;
        return;
      }
      this.axios
        .post("PRODUCTADD", {
          productGroupCode: this.$route.params.code,
          skuIdList: this.selectProduct,
        })
        .then((res) => {
          if (res.code === 0) {
            this.$message({
              type: "success",
              message: "添加成功",
            });
            this.getList();
          } else {
            this.$message.error(res.msg);
          }
          this.dialogVisible = false;
        });
    },
    onOpenAdd() {
      this.dialogVisible = true;
      this.selectProduct = [];
      this.getProductList();
    },
    onClearProduct() {
      this.$confirm("您确定要清空所有产品吗？", "提示", {
        confirmButtonText: "确定",
        canceButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.axios
          .postQuery("PRODUCTCLER", {
            productGroupCode: this.$route.params.code,
          })
          .then((res) => {
            if (res.code === 0) {
              this.$message({
                type: "success",
                message: "清空成功",
              });
              this.getList();
            } else {
              this.$message.error(res.msg);
            }
          });
      });
    },
    getList() {
      this.axios
        .get("PRODUCTRELATIONDetail", {
          productGroupCode: this.$route.params.code,
          pageNum: this.page.current,
          pageSize: this.page.size,
        })
        .then((result) => {
          console.log("result", result);
          if (result.code === 0) {
            this.tableData = result.data.list;
            this.page.total = result.data.total;
          }
        });
    },
    // 每页多少条
    handleSizeChange(val) {
      this.page.size = val;
      this.getList();
    },
    // 切换到第几页
    handleCurrentChange(val) {
      this.page.current = val;
      this.getList();
    },
    onDelete(row) {
      this.$confirm("您确定要删除该产品吗？", "提示", {
        confirmButtonText: "确定",
        canceButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.axios
          .postQuery("PRODUCTRELATIONDELETE", {
            productGroupCode: this.$route.params.code,
            skuId: row.skuId,
          })
          .then((res) => {
            if (res.code === 0) {
              this.$message({
                type: "success",
                message: "删除成功",
              });
              this.getList();
            } else {
              this.$message.error(res.msg);
            }
          });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  overflow-y: auto;
  height: 90vh;
  .card-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .card-header-left {
      display: flex;
      flex-direction: row;
      align-items: center;
      .top-title {
        font-size: 16px;
        color: #262626;
        font-weight: bold;
      }
      .question {
        width: 16px;
        height: 16px;
        margin-left: 4px;
      }
      .question:hover {
        cursor: pointer;
      }
    }
    .card-header-right {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
    }
  }
  .auth-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .auth-show-input {
    width: 300px;
  }
}
.input-with-select .el-input-group__prepend {
  background-color: #fff;
}
</style>
